var SimplePopup = require('../../../vendor/simplepopup/simplePopup.js');
var Newsletter = {
	createTrigger: function () {
		$('.newsletter-section form .newsletter-btn').bind('click.popup', function (e) {
			e.preventDefault();
			Newsletter.createPopup(this);
		});
	},
	createPopup: function (obj) {

		new SimplePopup({
			'contentClass': 'newsletter-confirm',
			'content': false,
			'contentHtmlId': 'newsletter-confirm',
			'contentCss': {
				'width': '30%',
			},
			'optionalData': obj,
			onAfterContentShow: function (el, self) {

				let obj = self.settings.optionalData;

				$(el).find('.consent-chk').on('change', function () {
					let checked = this.checked;
					if (checked == true) {
						$(obj).unbind(".popup");
						$(obj).parents('form').find('input[name="form[consent]"]').attr('value', 1);
						self.removePopup(self);
					}
				});

			},
			onBeforeContentClose: function (el, self) {
				let obj = self.settings.optionalData;

				if ($(obj).parents('form').find('input[name="form[consent]"]').attr('value') == 1) {
					$(obj).click();
				}

			},
		});
	}
}



Newsletter.createTrigger(true);

module.exports = Newsletter;